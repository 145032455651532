import { NotificationResponse, Notification } from "~/types/Notification";

export const useNotifications = () => {
  const getMappedNotifications = (
    notifications: Array<NotificationResponse>,
  ): Array<Notification> => {
    const mappedNotifications = notifications.map((notification) => {
      return {
        id: notification.id,
        uuid: notification.uuid,
        title: notification.title,
        createdAt: new Date(notification.createdAt),
        updatedAt: new Date(notification.updatedAt),
        idAppUser: notification.idAppUser,
        viewed: notification.viewed,
        type: notification.type,
        body: notification.body,
      };
    });

    mappedNotifications.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedNotifications;
  };

  const notify = (errorMaybe: string | null, successMessage: string | null) => {
    const notificationStore = useNotificationStore();

    if (errorMaybe) {
      notificationStore.notify({
        type: "error",
        message: errorMaybe,
      });
    } else if (successMessage) {
      notificationStore.notify({
        type: "success",
        message: successMessage,
      });
    }
  };

  return {
    getMappedNotifications,
    notify,
  };
};
