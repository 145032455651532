import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { NotificationResponse, Notification } from "~/types/Notification";
import { TimeoutableToast, Toast } from "~/types/shared";

export const useNotificationStore = defineStore({
  id: "notification-store",
  state: () => {
    return {
      notifications: [],
      toasts: [],
    } as {
      notifications: Array<Notification>;
      toasts: Array<TimeoutableToast>;
    };
  },
  actions: {
    notify(notification: Toast) {
      const id = uuidv4();
      const timestamp = new Date();
      timestamp.setMilliseconds(
        timestamp.getMilliseconds() + this.toasts.length,
      );
      this.toasts.push({ ...notification, timestamp, id });
    },

    removeToast(id: string) {
      const index = this.toasts.findIndex((toast) => toast.id === id);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },

    async listNotifications() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { notifications: Array<NotificationResponse> };
      }>("notification");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedNotifications } = useNotifications();
        this.notifications = getMappedNotifications(
          data.value.data.notifications,
        );
      }
      return this.notifications;
    },

    async markAsRead(input: {
      notificationId?: number | undefined | null;
      markAll: boolean;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: Record<string, never>;
      }>("/notification/mark-as-read", {
        method: "POST",
        body: {
          notificationId: input.notificationId,
          markAll: input.markAll,
        },
      });

      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }

      if (!data.value) {
        return [];
      }

      if (input.markAll) {
        this.notifications.forEach((notification) => {
          notification.viewed = true;
        });
      } else if (input.notificationId != null) {
        const notification = this.notifications.find(
          (notification) => notification.id === input.notificationId,
        );
        if (notification) {
          notification.viewed = true;
        }
      }

      return this.notifications;
    },
  },
  getters: {
    numUnviewedNotifications(state) {
      return state.notifications.filter((n) => !n.viewed).length;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationStore, import.meta.hot),
  );
}
